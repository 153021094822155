
import { Component, Mixins } from "vue-property-decorator";
import FormValidator from "@/mixins/FormValidator";
import DialogService from "@/components/request/DialogService.vue";

@Component({
  components: {
    DialogService
  }
})
export default class SearchService extends Mixins(FormValidator) {
  services: any = [];
  categorias: any = [];
  options_services: any[] = [];
  options_motives = [
    { label: "Solicitud", value: "0" },
    { label: "Reclamo", value: "1" },
    { label: "Sugerencia", value: "2" }
  ];
  service_selected: any = "";
  motive_selected: any = {};
  category_selected: any = {};
  filter_string: string = "";

  private beforeCreate() {
    this.$q.loading.show();
  }

  private mounted() {
    window.scroll(0, 0);
    this.$axios
      .get("servicios/listar/v2")
      .then(response => {
        this.services = response.data.servicios;
        this.categorias = response.data.categorias;

        /* dgongora 20210217 - modificada la rutina que selecciona el servicio.
           Rutina anterior no encontraba el servicio correctamente usando find sobre el arreglo
           Posiblemente debido a que objetos reactivos no se pueden manipular normalmente.
        */
        let service_id: any = this.$route.params.service_id;
        if (service_id !== "") {
          let lookup: any[] = [];
          let temp: any = {};
          for (var i = 0, len = this.services.length; i < len; i++) {
            temp = this.services[i];
            lookup[temp!.id] = this.services[i];
          }
          let service = lookup[service_id];
          this.selectService(service);
        }
      })
      .catch(error => {
        this.$q.notify({
          message: this.$t("notification.problem") as string,
          color: "accent",
          position: "top",
          timeout: 5000
        });
      })
      .finally(() => {
        this.$q.loading.hide();
      });
  }

  private stepOneSubmit() {
    if (Object.keys(this.service_selected).length) {
      let ticket: any = {
        step: 2,
        service_id: this.service_selected.id,
        service_name: this.service_selected.nombre,
        motive: Number(this.motive_selected.value)
      };
      this.$store.dispatch("submitRequestStepOne", ticket);
      if (this.service_selected.id && this.service_selected.casa_segura) {
        this.$router.push({ name: "safehouserequest" });
      }
    } else {
      this.$q.notify({
        message: this.$t("notification.no_service_selected") as string,
        color: "accent",
        position: "top",
        timeout: 5000
      });
    }
  }

  private moreHelpRequested() {
    this.stepOneSubmit();
  }

  private async scrollToCategory(category_id: any) {
    let expansion_item = "expansion-" + category_id;
    let options = {
      behavior: "smooth",
      alignToTop: true
    };
    //sorry
    await new Promise(resolve => setTimeout(resolve, 375));
    this.$nextTick(() => {
      document.getElementById(expansion_item)!.scrollIntoView(options as any);
    });
  }

  private selectService1(service: any): void {
    if (service) {
      this.service_selected = service;
      if (service.tipo == 3)
        // presencial
        document.location.assign(service.servicios_url[0].url);
      else (this.$refs as any).DialogService.dialog = true;
    }
  }

  private selectService(service: any): void {
    if (service) {
      this.$q.loading.show();
      this.$axios
        .post("servicio_urls/listar/front", {
          id_servicio: service.id ? service.id : null
        })
        .then(response => {
          service.servicios_url = response.data;
        })
        .then(response => {
          this.service_selected = service;
          if (service.tipo == 3) {
            if (service.servicios_url.length > 0) {
              // presencial
              document.location.assign(service.servicios_url[0].url);
            }
          } else (this.$refs as any).DialogService.dialog = true;
        })
        .catch(error => {})
        .finally(() => {
          this.$q.loading.hide();
        });
    }
  }

  private boldCoincidence(str: string, query: string): string {
    const n = str.toUpperCase();
    const q = query.toUpperCase();
    const x = n.indexOf(q);
    if (!q || x === -1) {
      return str; // bail early
    }
    return (
      str.substr(0, x) +
      "<b>" +
      str.substr(x, q.length) +
      "</b>" +
      str.substr(x + q.length)
    );
  }

  private filterService(val: string, update: any) {
    this.filter_string = val;
    if (val == null) {
      return;
    }
    if (val === "") {
      update(() => {
        this.options_services = this.services;
      });
      return;
    }

    update(() => {
      const value = val.toLowerCase();
      this.options_services = this.services.filter((service: any) => {
        let filtered = service.nombre.toLowerCase().indexOf(value) > -1;
        return filtered;
      });
    });
  }
}
